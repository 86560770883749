<div class="u-flex-row u-flex-justify-content-center u-padding-top-triple u-padding-right u-padding-bottom-triple u-padding-left">
    <div class="u-container">
        <app-card class="u-flex-row u-flex-justify-content-center" *ngIf="move$ | async as move">
            <app-empty-state [direction]="uiDirection.Horizontal">
                <app-svg-illustration [svg]="illustrations.EnergyBulb" [context]="uiContext.Accent"></app-svg-illustration>
                <h5>{{ 'ITS_A_WRAP' | translate }}</h5>
                <div empty-state-info>
                    <app-todo
                        *ngIf="isEots"
                        [label]="'ENERGY_OFFER_SENT' | i18nKeyTranslate: i18nKeys.RealEstateAgentTodo"
                        [checked]="true"
                    >
                        <app-todo-template *todoStateChecked>
                            <span class="u-font-small u-color-muted">{{ 'BY_EMAIL' | translate }}</span>
                        </app-todo-template>
                    </app-todo>
                    <app-todo
                        *ngIf="move.linkedMove"
                        [label]="'LEAVER_SUPPLIERS_REQUESTED' | i18nKeyTranslate: i18nKeys.RealEstateAgentTodo"
                        [checked]="true"
                    >
                        <app-todo-template *todoStateChecked>
                            <span class="u-font-small u-color-muted">{{ 'BY_EMAIL' | translate }}</span>
                        </app-todo-template>
                    </app-todo>
                </div>
                <button app-button (click)="openMoveDetail()">{{ 'VIEW_TRANSACTION' | translate }}</button>
            </app-empty-state>
        </app-card>
    </div>
</div>
